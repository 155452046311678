<template>
    <Section class="bg-white">
        <OnScrollIn fade translate>
            <div class="mx-auto max-w-7xl overflow-clip rounded-xl bg-slate-50 py-12 ring-1 ring-slate-200 sm:py-24">
                <div class="mx-auto max-w-2xl text-center">
                    <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Kundenbewertungen</h2>
                    <p class="mt-2 text-lg leading-8 text-gray-600">
                        Erfahren Sie, wie unsere Kunden die Rollstühle von Mimovo wahrnehmen.
                    </p>
                </div>

                <div class="mx-auto mt-14 md:mt-20">
                    <swiper-container
                        :centered-slides="true"
                        :loop="true"
                        :speed="6000"
                        :space-between="10"
                        :slides-per-view="1.2"
                        :autoplay="{
                            delay: 2,
                            disableOnInteraction: true,
                        }"
                        :breakpoints="{
                            768: {
                                slidesPerView: 3.4,
                                spaceBetween: 20,
                            },
                        }"
                    >
                        <swiper-slide v-for="testimonial in testimonials" :key="testimonial.id">
                            <div class="py-2">
                                <PostCard :post="testimonial" />
                            </div>
                        </swiper-slide>
                    </swiper-container>
                </div>
            </div>
        </OnScrollIn>
    </Section>
</template>

<script setup>
import { register } from 'swiper/element/bundle'
register()

import Section from '@/Components/Fragments/BaseSection.vue'
import PostCard from '@/Components/PostCard.vue'
import OnScrollIn from '@/Components/Animations/OnScrollIn.vue'

defineProps({
    testimonials: Object,
})
</script>
