<template>
    <figure class="rounded-2xl bg-white p-4 text-sm leading-6 ring-1 ring-slate-200">
        <div class="relative w-full" v-if="post.imageUrl">
            <img
                :src="post.imageUrl"
                alt=""
                class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
            />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
        </div>

        <div class="mt-4">
            <div class="mt-2 font-semibold">
                <!-- <p>
                    {{ post.title }}
                </p> -->
            </div>
            <blockquote class="text-gray-900">
                <p>{{ `“${post.body}”` }}</p>
            </blockquote>
            <figcaption class="mt-6 flex items-center gap-x-4">
                <!-- <img
                    class="h-10 w-10 rounded-full bg-gray-50"
                    :src="post.author.imageUrl"
                    alt=""
                /> -->
                <div
                    className="flex items-center justify-center p-2 bg-sky-600 rounded-full w-10 h-10"
                    :style="'background-color: ' + stringToHslColor(post.author.name)"
                >
                    <div
                        className="flex items-center justify-center w-5 h-5 text-md text-white font-semibold rounded-full  "
                    >
                        {{
                            post.author.name[0].toUpperCase() +
                            post.author.name.split(' ').slice(-1)[0][0].toUpperCase()
                        }}
                    </div>
                </div>
                <div>
                    <div class="font-semibold text-gray-900">
                        {{ post.author.name }}
                    </div>
                    <div class="text-gray-600">
                        {{ `@${post.author.handle}` }}
                    </div>
                </div>
            </figcaption>
        </div>
    </figure>
</template>

<script setup>
defineProps({
    post: Object,
})

function stringToHslColor(str) {
    var hash = 0
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    var h = hash % 360
    return 'hsl(' + h + ', ' + 50 + '%, ' + 50 + '%)'
}
</script>
